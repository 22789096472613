/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "dwollaWebhookEndpoint",
            "endpoint": "https://iqkck2nazb.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "hnCollaborativeAuthorizationEndpoint",
            "endpoint": "https://bzknyupoil.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "hnWebhookEndpoint",
            "endpoint": "https://40lm7ib7lb.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://tseta5e5zbd6vjcwwb3zup7pku.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-nx5nnxcvbbbddcbqqadejqi3nu",
    "aws_cognito_identity_pool_id": "us-east-2:c494b23a-7664-4098-ac10-fed824dd9778",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_ivJyPXUVW",
    "aws_user_pools_web_client_id": "1avqgaucr9pj53jgvgpn3hitgh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "userb6558-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
